<template>
    <div class="topbar">
        <div class="left">
            <div class="img-wrap">
                <img src="../../assets/humanResources/anzhi_Logo.png" alt="">
            </div>
            <div class="line"></div>
            <div class="title">安知人力资源服务平台</div>
            <div class="version">正式版</div>
        </div>
        <div class="right">
            <!-- <div class="img-wrap">
                <img src="../../assets/humanResources/smallBell.png" alt="">
            </div> -->
            <!-- <div class="line"></div> -->
            <div class="compName">{{authInfo.compName}}</div>
            <div class="auth" @click="goAuth(1)" :style="authInfo.compAuthState==30?authStyleSuccess:authStyleError">{{compAuchDic[authInfo.compAuthState]}}</div>
            <el-tooltip v-if="authInfo.compAuthState==40" class="item" effect="dark" :content="authInfo.compAuthReason" placement="bottom">
                <div class="what">
                    <img src="../../assets/humanResources/what.png" alt="">
                </div>
            </el-tooltip>
            <div class="line"></div>
            <div class="mobile">{{authInfo.fullname}}</div>
            <div class="auth" @click="goAuth(2)" :style="authInfo.userAuthState==30?authStyleSuccess:authStyleError">{{personAuthDic[authInfo.userAuthState]}}</div>
            <el-dropdown trigger="click" size="small" @command="handleCommand">
                <span class="el-dropdown-link">
                    <img class="down" src="../../assets/humanResources/allow-down.png" alt="">
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="logout">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
    import PubSub from "pubsub.js";
    export default {
        name:"Topbar",
        data(){
            return{
                // 企业认证信息
                authInfo:{},
                // 个人认证字典数据
                personAuthDic:{},
                // 企业认证字典数据
                compAuchDic:{},
                // 认证状态未通过颜色
                authStyleError:{
                    "backgroundColor":"#ff3c0033",
                    "color":"#FF3C00"
                },
                // 认证通过颜色
                authStyleSuccess:{
                    "backgroundColor": "#48db9933",
                    "color": "#48DB99"
                },
                // 订阅的事件名称
                subscribeName:null
            }
        },
        methods:{
            // 初始化
            initData(){
                this.getPersonAuthDic();
                this.getCompAuchDic();
                this.getAuthInfo();
            },
            // 获取认证信息
            getAuthInfo(){
                this.$post("/hr/auth/info",{}).then(res=>{
                    this.authInfo=res.data;
                }).catch(e=>{
                    console.error("获取认证信息出错",e);
                });
            },
            // 退出
            logout(){
                this.$post("/logout", {});
                this.$store.dispatch("handleDelAll");
                this.$router.push({
                    path: "/web/login",
                });
            },
            // 获取个人认证字典数据
            getPersonAuthDic(){
                const obj=this.$setDictionary("HR_ADMIN_AUTH", "list")||{};
                this.personAuthDic=obj;
            },
            // 获取公司认证字典数据
            getCompAuchDic(){
                const obj=this.$setDictionary("HR_COMP_AUTH", "list")||{};
                this.compAuchDic=obj;
            },
            handleCommand(command){
                command();
            },
            // 点击去认证
            goAuth(type){
                // type 认证类型 1公司认证 2个人认证
                const {authInfo}=this;
                if(type===1){
                    // 没有认证，点击去认证
                    if(authInfo.compAuthState==10 || authInfo.compAuthState==40){
                        this.$router.push({
                            path:"/azhr/humanResources/enterpriseCertification",
                            query:{
                                authInfo
                            }
                        });
                    }
                }else{
                    // 没有认证，点击去认证
                    if(authInfo.userAuthState==10){
                        this.$router.push({
                            path:"/azhr/humanResources/personalCertification",
                            query:{
                                authInfo
                            }
                        });
                    }
                }
            }
        },
        created(){
            this.initData();
            // 订阅消息，企业认证或者个人认证重新获取信息
            this.subscribeName=PubSub.subscribe("authCallback",()=>{
                this.getAuthInfo();
            });
        },
        beforeDestroy(){
            PubSub.unsubscribe(this.subscribeName);
        }
    }
</script>

<style scoped lang="less">
    .topbar{
        height: 3rem;
        background: #FFFFFF;
        box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);
        overflow: hidden;
        padding: 0 1.875rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 0;
        // 左侧
        .left{
            display: flex;
            height: 100%;
            align-items: center;
            flex-shrink: 0;
            .img-wrap{
                width: 2.9375rem;
                height: 1.5rem;
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .line{
                width: 1px;
                height: 1rem;
                background: #999999;
                margin: 0 .625rem;
            }
            .title{
                font-size: .875rem;
                font-weight: 600;
                color: #333333;
                margin-right: .625rem;
                line-height: .875rem;
            }
            .version{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3.25rem;
                height: 1.25rem;
                background: #2877ff2d;
                border-radius: 10px;
                font-size: .75rem;
                color: #2878FF;
            }
        }
        // 右侧
        .right{
            display: flex;
            height: 100%;
            align-items: center;
            flex-shrink: 0;
            margin-left: 50px;
            .img-wrap{
                width: .9375rem;
                height: 1rem;
                img{
                    display: block;
                    height: 100%;
                    width: 100%;
                }
            }
            .what{
                margin-left: 5px;
                width: 14px;
                height: 14px;
                cursor: pointer;
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            .compName{
                font-size: .875rem;
                color: #666666;
                line-height: .875rem;
            }
            .mobile{
                font-size: .875rem;
                color: #333333;
                line-height: .875rem;
            }
            .line{
                width: 1px;
                height: 1rem;
                background: #999999;
                margin: 0 .625rem;
            }
            .auth{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3.25rem;
                height: 1.25rem;
                border-radius: 10px;
                font-size: .75rem;
                margin-left: 4px;
                cursor: pointer;
            }
            .el-dropdown{
                margin-left: .625rem;
                .down{
                    display: block;
                    width: 1rem;
                    height: .5rem;
                }
            }
        }
    }
</style>