<template>
  <transition name="el-notification-fade">
    <div
      :class="['el-notification', customClass, horizontalClass, {'download-zoom-width':!contentVisible}, {'download-notify-highlight':highlight}]"
      v-show="visible"
      :style="positionStyle"
      style="display:block"
      v-drag
    >
      <div class="df" style="padding:10px 0;">
        <img
          class="el-notification__icon el-notification__icon_custom"
          src="../assets/flie_download.png"
          alt
        />
        <div style="display:flex;align-items:center;min-width:100px">
          <el-badge :value="downloadItems.length" v-show="!contentVisible">
            <h2 class="el-notification__title" v-text="title"></h2>
          </el-badge>
          <h2 class="el-notification__title" v-text="title" v-show="contentVisible"></h2>
        </div>
        <div>
          <div
            class="nodrag hiddenBtn el-icon-caret-top"
            @click.stop="contentVisible = true"
            v-if="!contentVisible"
            title="展开"
          ></div>
          <div
            class="nodrag hiddenBtn el-icon-caret-bottom"
            @click.stop="contentVisible = false"
            v-if="contentVisible"
            title="收缩"
          ></div>
          <div
            class="nodrag el-notification__closeBtn el-icon-close position_custom"
            @click.stop="close"
          ></div>
        </div>
      </div>
      <div v-show="contentVisible">
        <p v-for="(item,index) in downloadList" :key="item" class="df" :style="index == downloadList.length-1?'margin-bottom:10px':'margin-bottom:19px'">
          <img src="../assets/list_round.png" alt class="list_round" />
          <span class="flexdc">
            <span style="font-size: 14px;line-height:14px;margin-bottom:6px">{{ item.fileName }}</span>
            <span>
              <span
                :class="item.taskState == '10' ? 'handle_color' : 'success_color'"
              >{{ item.taskState == '10' ? '处理中' : '已完成'}}</span>
              <span class="load_fail_btn" v-show="item.taskState == '50' || item.taskState == '20' || item.taskState == '10'">
                <el-link
                  v-for="(item_1,index_1) in item.downloadList"
                  :key="index_1"
                  class="nodrag"
                  type="primary"
                  :href="item_1.key"
                  v-show="item.taskState == '20' || item.taskState == '10'"
                  download
                >下载：{{ item_1.value }}</el-link>
                <el-popover
                  placement="top-start"
                  title="失败描述"
                  width="200"
                  trigger="hover"
                  v-show="item.taskState == '50'"
                  :content="item.errMsg"
                >
                  <el-link
                    class="nodrag"
                    type="primary"
                    slot="reference"
                  >失败</el-link>
                </el-popover>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "download-notification",
  props: {
    visible: Boolean,
    position: {
      default: "bottom-right"
    }
  },
  directives: {
    drag(el, binding) {
      let disX, disY;
      function handleDownCb(e) {
        if (
          e.target.className.indexOf("nodrag") > -1 ||
          e.target.parentNode.className.indexOf("nodrag") > -1
        ) {
          return;
        }
        disX = e.layerX;
        disY = e.layerY;
        document.addEventListener("mousemove", handleMoveCb);
        document.addEventListener("mouseup", handleUpCb);
        e.stopPropagation();
      }

      el.addEventListener("mousedown", handleDownCb);

      function handleMoveCb(e) {
        let x = e.clientX - disX;
        let y = e.clientY - disY;
        el.style.left = x + "px";
        el.style.top = y + "px";
        el.style.bottom = null;
        e.stopPropagation();
        return;
      }

      function handleUpCb() {
        document.removeEventListener("mousemove", handleMoveCb);
        document.removeEventListener("mouseup", handleUpCb);
      }
    }
  },
  data() {
    return {
      title: "下载列表",
      customClass: "download-notify",
      iconClass: "el-icon-download",
      period: 10000,
      verticalOffset: 10,
      contentVisible: true,
      highlight: false,
      timer: null,
      highlightTimer: null,
      downloadList: []
    };
  },
  computed: {
    horizontalClass() {
      return this.position.indexOf("right") > -1 ? "right" : "left";
    },
    verticalProperty() {
      return /^top-/.test(this.position) ? "top" : "bottom";
    },
    positionStyle() {
      return {
        [this.verticalProperty]: `${this.verticalOffset}px`
      };
    },
    ...mapGetters({
      downloadItems: "getDownloadItems"
    })
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.loadData();
        this.timer = setInterval(this.loadData, this.period);
      }
    },
    downloadItems() {
      this.highlight = true;
      this.highlightTimer = setTimeout(() => {
        this.highlight = false;
        clearTimeout(this.highlightTimer);
      }, 300);
      this.loadData();
    }
  },
  methods: {
    close() {
      this.$confirm("关闭后,下载列表将会清空,你确定要关闭么?", "提示")
        .then(() => {
          this.$store.dispatch("clearDownloadItems");
          this.$post("/sys/download/deleteRecord");
          clearInterval(this.timer);
        })
        .catch(() => {});
    },
    loadData() {
      // 下载完成的
      let taskIds = this.downloadItems || [];
      taskIds = taskIds.filter(g => g && (g > 0 || g.length > 0));
      //下载列表为空时不请求
      if (!taskIds.length) {
        return;
      }
      this.$post("/sys/download/queryDownloadState", { taskIds }, false)
        .then(re => {
          if (re.status == 0) {
            this.downloadList = re.data;
          } else {
            return Promise.reject(re);
          }
        })
        .catch(re => {
          this.$message.error("服务器未知异常");
          console.error(re);
        });
    }
  },
  mounted() {
    if (this.visible) {
      this.loadData();
      this.timer = setInterval(this.loadData, this.period);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>
<style lang="less" scoped>
.download-notify {
  z-index: 4;
  background-color: #fff;
  transition: background-color 0.3s;
  .hiddenBtn {
    position: absolute;
    top: 22px;
    right: 40px;
    cursor: pointer;
    color: #909399;
    font-size: 16px;
  }
  .position_custom {
    top: 22px;
  }
}
.el-notification {
  max-height: 600px;
  overflow-y: scroll;
}
.el-notification__title {
  line-height: 16px;
  font-size: 16px;
  color: #333;
}
.download-notify-highlight {
  background-color: #f4ff00;
}

.el-notification__icon_custom {
  width: 20px;
  height: 20px;
  margin-right: 9px;
}
.list_round {
  width: 6px;
  height: 6px;
  position: relative;
  top: 4px;
  left: 7px;
  margin-right: 17px;
}
</style>
<style lang="less">
.download-zoom-width {
  .el-badge__content.is-fixed {
    top: 3px;
    right: -4px;
  }
}
.success_color {
  color: #00bd16;
  font-size: 14px;
  line-height: 14px;
}
.handle_color {
  color: #fea10f;
  font-size: 14px;
  line-height: 14px;
}
.load_fail_btn {
  background-color: #F2F7FD;
  line-height: 14px;
  font-size: 14px;
  display: block;
  padding: 5px 10px;
  color: #5B6BE7;
  border-radius: 4px;
  .el-link {
    display: block;
    line-height: 30px;
  }
}
</style>
